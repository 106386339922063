<template>
    <Overlay :show="!!confirm" class="overlay-center overlay-confirmable px-container" @click="handleWindowClick">
        <div v-if="confirm" class="limited">
            <div ref="content" class="content-box">
                <div class="content-box-section">
                    <p class="fs-20 text-center" v-html="confirm.text"></p>
                    <div class="d-flex justify-content-center align-items-center mt-4">
                        <button @click="accept" type="button" class="btn m-1">{{ confirm.confirm_text || `Confirm` }}</button>
                        <button @click="cancel" type="button" class="btn btn-dark-white m-1">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </Overlay>
</template>

<script>
import Overlay from "@/components/Overlay.vue";

export default {
    name: "Confirmable",
    components: {Overlay},
    props: {
        confirm: Object
    },
    methods: {
        handleWindowClick(event){
            if(!this.isPartOfElement(event.target, this.$refs.content)){
                this.cancel();
            }
        },
        handleKeyDown(event){
            if(this.confirm){
                if(event.keyCode === 13){
                    this.accept();
                } else if(event.keyCode === 27){
                    this.cancel();
                }
            }
        },
        cancel(){
            this.$root.confirm = null;
        },
        async accept(){
            if(typeof this.confirm.handler === 'function'){
                await this.confirm.handler();
            }
            this.cancel();
        }
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);
    },
    unmounted() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }
}
</script>

<style>
.overlay-confirmable{
    z-index: 99;
    background-color: rgba(4, 5, 6, 0.8);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}
</style>