<template>
    <span class="badge">
        <slot/>
    </span>
</template>

<script>
export default {
    name: "Badge",
    props: {
        text: String
    }
}
</script>

<style>
.badge{
    line-height: 1;
    font-size: 0.6em;
    font-weight: bold;
    white-space: nowrap;
    text-transform: uppercase;

    color: var(--like-black);
    background-color: var(--primary);
    border-radius: 0.2em;

    padding: 0.1em 0.4em;
    margin-left: 0.4em;
}
.badge.badge-light{
    background-color: var(--like-white);
}
.badge.badge-white{
    background-color: white;
}
.badge.badge-dark-primary{
    background-color: var(--dark-primary);
}
.badge.badge-dark-light{
    background-color: var(--like-dark-white);
}
.badge.badge-darkest-light{
    background-color: var(--like-darkest-white);
}
.badge.badge-red{
    background-color: var(--red);
}
</style>