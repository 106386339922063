<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor" class="icon">
        <path d="m32.73,0H7.27C3.25,0,0,3.25,0,7.27v25.46c0,2.84,1.62,5.3,4,6.49V15.27c0-6.21,5.06-11.27,11.27-11.27h23.95c-1.19-2.38-3.65-4-6.49-4Zm15.27,15.27v25.46c0,4.02-3.25,7.27-7.27,7.27H15.27c-4.02,0-7.27-3.25-7.27-7.27V15.27c0-4.02,3.25-7.27,7.27-7.27h25.46c4.02,0,7.27,3.25,7.27,7.27Z"/>
    </svg>
</template>

<script>
export default {
    name: "ScreensIcon"
}
</script>