<template>
    <div class="alert">
        <slot></slot>

        <button @click="$emit('close')" type="button" class="btn btn-action alert-close-button">
            <CloseIcon/>
        </button>
    </div>
</template>

<script>
import CloseIcon from "@/components/icons/CloseIcon.vue";

export default {
    name: "Alert",
    components: {CloseIcon},
    emits: ['close']
}
</script>

<style>
.alert-container .alert{
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 1.2rem;
    background-color: var(--content-background-4);
    border-radius: 0.4rem;
    margin-top: 0.4rem;

    font-size: 0.8rem;
}
.alert-container .alert.alert-primary{
    background-color: var(--content-background-primary-2);
}
.alert-container .alert.alert-red{
    background-color: #370d0f;
}
.alert-container .alert::before{
    content: "";
    position: absolute;
    top: -30%;
    left: 0;
    height: 160%;
    width: 16%;
    transform: rotate(10deg);
    filter: blur(8px);
    opacity: 0.16;
    background-color: white;
    animation: alert-spawn-animation 0.4s ease-in forwards;
    animation-delay: 0.16s;
}
@keyframes alert-spawn-animation {
    0%{
        left: 0;
    }
    100%{
        left: 120%;
    }
}
.alert-container .alert-close-button{
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
}
</style>