<template>
    <div @click.prevent="show = !show" class="dropdown">
        <slot></slot>

        <ul v-show="show" class="dropdown-options">
            <slot name="options"></slot>
        </ul>
    </div>
</template>

<script>
import DropdownOption from "@/components/input/Dropdown/DropdownOption";

export default {
    name: "Dropdown",
    components: {DropdownOption},
    data: () => ({
        show: false
    }),
    computed: {
        active: function(){
            for(const option of this.options){
                if(option.value === this.modelValue)
                    return option;
            }
            return null;
        }
    },
    methods: {
        update(value){
            this.show = false;
            this.$emit('update:modelValue', value);
            this.$emit('update', value);
        }
    }
}
</script>

<style>
    .dropdown{
        position: relative;
        user-select: none;
    }
    .dropdown .dropdown-options{
        position: absolute;
        top: 100%;
        right: 0;
        width: 16rem;

        list-style: none;
        padding: 0.4rem;
        margin: 0.8rem 0 0;

        overflow: hidden;
        background-color: var(--content-background-2);
        box-shadow: 0 0 1rem var(--like-black);
    }
    .dropdown .dropdown-options,
    .dropdown .dropdown-option{
        border-radius: 0.4rem;
    }
    .dropdown .dropdown-option{
        position: relative;
        display: flex;
        align-items: center;
        height: 1.8rem;
        padding: 0.4em 0.8em;
        background-color: var(--content-background-2);

        font-size: 0.8rem;
    }
    .dropdown .dropdown-option:hover{
        cursor: pointer;
        background-color: var(--content-background-3);
    }

    .dropdown .dropdown-section{
        font-size: 0.8rem;

        border-bottom: 1px solid var(--content-background-3);
        margin-bottom: 0.4rem;
        padding: 0.4rem 0.4rem 0.8rem;
    }

    .dropdown .dropdown-option > .icon{
        height: auto;
        width: 0.9em;
        margin-right: 1.2em;
    }
</style>