<template>
    <div class="py-container">
        <div class="full-width-container px-container">
            <div class="content-box">
                <div class="content-box-section py-5">
                    <h1 class="fs-64 text-center">Tool Index</h1>
                </div>
            </div>
        </div>

        <div class="container px-container">
            <div v-for="(parent, index) of $root.nested_tools" :key="index" class="content-box mt-4">
                <div class="content-box-section">
                    <div class="content-box-section content-box-header">
                        <h2 class="fs-32 weight-bold text-center" :style="{color: `var(--tool-${parent.id})`}">
                            <component v-if="parent.icon" :is="parent.icon"/>
                            <span>{{parent.title}}</span>
                            <span class="txt-like-dark-white"> Tools</span>
                        </h2>
                    </div>

                    <div class="my-4 py-4 position-relative">
                        <div class="vertical-dotted-line"></div>

                        <div class="d-flex justify-content-center position-relative">
                            <div class="d-flex justify-content-center align-items-center rounded-circle fs-20 bg-cbg-2" style="width:2rem;height:2rem;">
                                <AngleIcon style="transform:rotate(90deg);"/>
                            </div>
                        </div>
                    </div>

                    <div>
                        <ToolListing :tools="parent.children" :placeholders="getPlaceholderCount(parent.children)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AngleIcon from "@/components/icons/AngleIcon.vue";
import Badge from "@/components/Badge.vue";
import HeartIcon from "@/components/icons/HeartIcon.vue";
import ToolListing from "@/components/ToolListing.vue";

export default {
    name: "Tools",
    components: {ToolListing, HeartIcon, Badge, AngleIcon},
    methods: {
        getPlaceholderCount(children){
            const rows = children.length / 3;
            if(rows !== parseInt(rows)){
                const totalItems = (parseInt(rows) + 1) * 3;
                return totalItems - children.length;
            }
            return 0;
        }
    }
}
</script>

<style>

</style>