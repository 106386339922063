<template>
    <div>
        <svg class="loader" viewBox="25 25 50 50">
            <circle r="20" cy="50" cx="50"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: "Loader",
}
</script>

<style>
svg.loader {
    height: 2.4em;
    transform-origin: center;
    animation: rotate4 2s linear infinite;
}
svg.loader.loader-inline,
.loader-inline svg.loader{
    height: 1.2em;
}

svg.loader circle {
    fill: none;
    stroke: var(--primary);
    stroke-width: 0.32em;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash4 {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dashoffset: -125px;
    }
}
</style>