<template>
    <li>
        <div class="dropdown-section">
            <slot></slot>
        </div>
    </li>
</template>

<script>
export default {
    name: "DropdownSection"
}
</script>