<template>
    <ul v-if="visible" class="breadcrumbs">
        <template v-for="(breadcrumb, index) of items" :key="index">
            <li>
                <router-link v-if="breadcrumb.route" :to="breadcrumb.route" class="breadcrumb">
                    {{breadcrumb.name}}
                </router-link>
                <span v-else class="breadcrumb">
                    {{breadcrumb.name}}
                </span>
            </li>
            <li v-if="(index + 1) < items.length" class="breadcrumb-seperator">
                <AngleIcon class="m-0"/>
            </li>
        </template>
    </ul>
</template>

<script>
import { useSchemaOrg, defineBreadcrumb } from "@unhead/schema-org";
import AngleIcon from "@/components/icons/AngleIcon.vue";

export default {
    name: "Breadcrumbs",
    components: {AngleIcon},
    props: {
        items: Array,
        visible: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        _schema: null
    }),
    watch: {
        items: function(){
            this.updateSchemaBreadcrumbList();
        }
    },
    methods: {
        updateSchemaBreadcrumbList(){
            const itemList = [];

            let pos = 1;
            for(const item of this.items){

                const listItem = {
                    position: pos++,
                    name: item.name
                };

                if(item.route){
                    const route = this.$router.resolve(item.route);
                    listItem.item = route.fullPath;
                }

                itemList.push(listItem);
            }

            this._schema = useSchemaOrg(
                defineBreadcrumb({
                    itemListElement: itemList
                })
            );
        }
    },
    mounted() {
        this.updateSchemaBreadcrumbList();
    }
}
</script>

<style>
.breadcrumbs{
    list-style: none;
    margin: 0;
    padding: 0 0.8rem 1.2rem 2rem;

    display: flex;
    flex-wrap: wrap;

    font-size: 0.8rem;
    font-weight: 200;
}
.breadcrumbs .breadcrumb-seperator{
    display: flex;
    align-items: center;
    font-size: 0.72em;
    padding: 0 0.4em;
}
.breadcrumbs .breadcrumb{
    display: block;
    padding: 0.1em 0.4em;
    border-radius: 0.4em;
}
.breadcrumbs a.breadcrumb:hover{
    background-color: var(--content-background-2);
}
.breadcrumbs .breadcrumb-seperator,
.breadcrumbs li:last-child .breadcrumb{
    color: var(--like-dark-white);
}
</style>