<template>
    <ul class="tool-listing">
        <li v-for="(tool, index) of tools" :key="index">
            <ToolLink :tool="tool" class="tool-item">
                <div class="tool-item-icon-container" :style="{'--bg-hover': `var(--tool-${tool.parent})`}">
                    <img v-if="tool.icon" :src="tool.icon['256']" :alt="`${tool.name} Tool logo`" class="tool-item-icon">
                    <img v-else src="/media/img/icon/helmet-icon-256.png" :alt="`${tool.name} Tool logo`" class="tool-item-icon">

                    <div v-if="tool.badges && tool.badges.length > 0" class="tool-item-badges-container fs-16">
                        <Badge v-for="(badge, index) of tool.badges" :key="index" :class="badge.class">{{badge.text}}</Badge>
                    </div>
                </div>
                <div>
                    <component :is="`h${headingLevel}`" class="tool-item-name" :title="tool.name">
                        <span>{{tool.name_compact || tool.name}}</span>
                    </component>
                    <p v-if="tool.about" class="tool-item-title">
                        {{tool.about.title}}
                    </p>
                </div>
            </ToolLink>
        </li>
        <li v-for="index of placeholders" :key="index">
            <div class="tool-item tool-item-placeholder">
                <div class="tool-item-icon-container"></div>
            </div>
        </li>
    </ul>
</template>

<script>
import ToolLink from "@/components/ToolLink.vue";
import Badge from "@/components/Badge.vue";

export default {
    name: "ToolListing",
    components: {Badge, ToolLink},
    props: {
        tools: Array,
        headingLevel: {
            type: String,
            default: "3"
        },
        placeholders: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style>
.tool-listing{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0 auto;

    width: 28.8rem;
    max-width: 100%;
}
@media screen and (max-width: 720px){
    .tool-listing{
        width: 9.6rem;
    }
}
.tool-listing .tool-item{
    display: block;
    width: 9.6rem;
    height: 14rem;
    padding: 0.8rem;
}
.tool-listing .tool-item:not(.tool-item-placeholder):hover{
    border-radius: 1rem;
    background-color: var(--like-black);
}
.tool-listing .tool-item .tool-item-icon-container{
    position: relative;
    width: 8rem;
    height: 8rem;
    padding: 0.4rem;
    border-radius: 0.8rem;
    background-color: var(--like-black);

    margin-bottom: 0.4rem;
}
.tool-listing .tool-item:not(.tool-item-placeholder):hover .tool-item-icon-container{
    background-color: var(--bg-hover);
}
.tool-listing .tool-item .tool-item-icon{
    height: 100%;
    max-width: 100%;
}
.tool-listing .tool-item .tool-item-badges-container{
    position: absolute;
    bottom: 0.4rem;
    left: 0.4rem;
}
.tool-listing .tool-item .tool-item-name{
    font-size: 1rem;
    font-weight: normal;
}
.tool-listing .tool-item .tool-item-title{
    font-size: 0.7rem;
    font-weight: 200;
    color: var(--like-dark-white);
    margin-top: 0.2rem;
}
</style>