<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" class="icon">
        <path d="m27.69,0H4.31C1.93,0,0,1.93,0,4.31v23.38c0,2.38,1.93,4.31,4.31,4.31h23.38c2.38,0,4.31-1.93,4.31-4.31V4.31c0-2.38-1.93-4.31-4.31-4.31Zm.37,12.78l-13.61,10.34,2.8,2.78c-1.2.78-2.42,1.56-3.62,2.35-1.15-1.13-2.28-2.28-3.42-3.42-1.79,1.37-3.56,2.76-5.35,4.14-.31-.3-.61-.61-.9-.9-.3-.3-.59-.59-.89-.9,1.37-1.77,2.75-3.56,4.12-5.35-1.15-1.15-2.29-2.28-3.42-3.42.78-1.2,1.56-2.42,2.35-3.62l2.78,2.8L19.22,3.95h8.83v8.83Z"/>
    </svg>
</template>

<script>
export default {
    name: "ModIcon"
}
</script>