<template>
    <a v-if="tool.external" :href="tool.external" target="_blank" :title="tool.name">
        <slot/>
    </a>
    <router-link v-else :to="{name: 'tool', params: {tool_id: tool.id}, query: {autoRedirect: true}}" :title="tool.name">
        <slot/>
    </router-link>
</template>

<script>

export default {
    name: "ToolLink",
    props: {
        tool: Object
    },
}
</script>