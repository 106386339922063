<template>
    <Page
        title="404"
        :breadcrumbs="[
            { name: `Home`, route: {name: `home`} },
            { name: `404 not found` }
        ]"
    >
        <div>
            <div style="margin-bottom:6rem;">
                <p class="text-center txt-white" style="margin-top:-2rem;">
                    <BellIcon/>
                    <span>Sorry but this page does not exist.</span>
                </p>
            </div>

            <div>
                <p class="text-center m-0">
                    Some other options to navigate to:
                </p>
                <div class="d-flex justify-content-center align-items-center flex-wrap">
                    <router-link :to="{name: 'tools'}">
                        <button class="btn btn-outline btn-white m-2">
                            <DashboardIcon/>
                            <span>Explore Tools</span>
                        </button>
                    </router-link>
                    <router-link :to="{name: 'guides'}">
                        <button class="btn btn-outline btn-white m-2">
                            <GuideIcon/>
                            <span>Explore Guides</span>
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import DashboardIcon from "@/components/icons/DashboardIcon.vue";
import GuideIcon from "@/components/icons/GuideIcon.vue";
import BellIcon from "@/components/icons/BellIcon.vue";

export default {
    name: "NotFound",
    components: {BellIcon, GuideIcon, DashboardIcon, Page}
}
</script>

<style>

</style>