<template>
    <transition name="fade">
        <div v-if="show" class="overlay">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Overlay",
    props: {
        show: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style>
    .overlay-container{
        position: relative;
        overflow: hidden;
    }
    .overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .overlay-center{
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease-in;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>