import { createRouter, createWebHistory } from 'vue-router';
import { useSeoMeta } from "@unhead/vue";

import Home from '@/views/Home.vue';
import Tools from "@/views/Tools.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [
    {
        path: '/', name: 'home', component: Home,
        meta: {
            title: `Pikatool`,
            description: `Better streaming for the best streamers. Improve your streaming quality.`,
            keywords: `Online Tools, Streamlining Utilities, Streamer Resources, Free Streaming Tools, User-Friendly Utilities, Streaming Enhancements, Essential Streaming Aids, Efficient Online Tools`
        }
    },
    {
        path: '/index', name: 'tools', component: Tools,
        meta: {
            title: `Pick a Tool`,
            description: `Explore our free, simple and useful tools.`,
            keywords: `Online Tools, Streamlining Utilities, Streamer Resources, Free Streaming Tools, User-Friendly Utilities, Streaming Enhancements, Essential Streaming Aids, Efficient Online Tools`
        }
    },
    {
        path: '/widget/:tool_id/:widget_id', name: 'widget', component: () => import('./views/Widget.vue'),
    },
    {
        path: '/tool/:tool_id/:tab?', name: 'tool', component: () => import('./views/Tool.vue'),
    },
    {
        path: '/guides', name: 'guides', component: () => import('./views/Guides.vue'),
        meta: {
            title: `Explore our Guides`,
            description: `Navigate seamlessly with our comprehensive guides. Unlock the full potential of our tools and elevate your experience with step-by-step instructions and valuable insights.`,
            keywords: `Tool Guides, Step-by-Step Instructions, User Tutorials, Tool Walkthroughs, How-To Guides, In-Depth Tool Insights, Comprehensive Guides, Effective Tool Usage`,
        }
    },
    {
        path: '/guide/:tool/:url_id', name: 'guide', component: () => import('./views/guides/Guide.vue'),
    },
    {
        path: '/contact', name: 'contact', component: () => import('./views/Contact.vue'),
        meta: {
            title: `Contact`,
            description: `Contact pikatool.com for support, feedback, or bug reports. Use the form or email us at info@rlns.de. Your input matters, and we're here to assist you promptly.`
        }
    },
    {
        path: '/about', name: 'about', component: () => import('./views/AboutUs.vue'),
        meta: {
            title: `About`,
        }
    },
    {
        path: '/donate', name: 'donate', component: () => import('./views/Donate.vue'),
        meta: {
            title: `Donate`,
        }
    },
    {
        path: '/privacy-policy', name: 'privacy_policy', component: () => import('./views/PrivacyPolicy.vue'),
        meta: {
            title: `Privacy Policy`,
        }
    },

    {
        path: '/twitch/giveaway', redirect: to => ({name: 'tool', params: {tool_id: 'twitch-giveaway'}}),
    },

    {
        path: '/:catchAll(.*)', name: 'not_found', component: NotFound,
        meta: {
            title: `Not found`,
        }
    }
  //{
  //  path: '/about',
  //  name: 'about',
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  component: () => import(/* webpackChunkName: "about" */ './views/AboutView.vue')
  //}
]

const defaultSeoMeta = {
    ogType: "website",
    ogImage: "/media/img/social_banner.jpg",
    ogUrl: window.location.href,
    // ogImage: meta.ogImage || null,
}

export const setSeoMeta = (input) => useSeoMeta({...defaultSeoMeta, ...input});

export const setCanonical = (url) => {
    let linkEl = document.head.querySelector(`link[rel="canonical"]`);
    if(!linkEl){
        linkEl = document.createElement('link');
        linkEl.setAttribute('rel', "canonical");
        document.head.appendChild(linkEl);
    }

    const currentUrl = linkEl.getAttribute('href');
    if(!currentUrl || currentUrl !== url){
        linkEl.setAttribute('href', url);
    }
}



export default createRouter({
    history: createWebHistory(),
    routes
});