<template>
    <div>
        <div class="d-flex justify-content-center mb-5">
            <div class="placeholder-loading" style="height:3.2rem;width:60%;"></div>
        </div>

        <div>
            <div class="row">
                <div ref="content" class="col-lg-8">
                    <div style="margin-bottom:8rem;">
                        <div class="placeholder-loading mb-2" style="width:40%;"></div>
                        <div class="placeholder-loading mb-2" style="width:48%;"></div>
                        <div class="placeholder-loading mb-2" style="width:24%;"></div>
                    </div>
                    <div>
                        <div class="placeholder-loading mb-2" style="width:40%;"></div>
                        <div class="placeholder-loading mb-2" style="width:48%;"></div>
                        <div class="placeholder-loading mb-2" style="width:24%;"></div>
                        <div class="placeholder-loading mb-2" style="width:40%;"></div>
                        <div class="placeholder-loading mb-2" style="width:48%;"></div>
                        <div class="placeholder-loading mb-2" style="width:24%;"></div>
                        <div class="placeholder-loading mb-2" style="width:40%;"></div>
                        <div class="placeholder-loading mb-2" style="width:48%;"></div>
                        <div class="placeholder-loading mb-2" style="width:24%;"></div>
                    </div>
                </div>
                <div class="col-4 d-none d-lg-block">
                    <div class="placeholder-loading ms-auto" style="width:18rem;height:20rem;"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PagePlaceholders"
}
</script>

<style>

</style>