<template>
    <footer>
        <div class="footer-top">
            <div class="container py-4">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <HeartIcon class="heart-pulse fs-40 txt-red me-2"/>
                        <div>
                            <div class="txt-white"><strong>Support me</strong></div>
                            <p>Support me and this project. Every contribution makes a difference.</p>
                        </div>
                    </div>

                    <a href="https://www.paypal.com/donate/?hosted_button_id=ZHXVKU5CF3JNS" target="_blank">
                        <button type="button" class="btn btn-outline fs-20" style="white-space:nowrap;">
                            <HeartIcon/>
                            <span>Donate</span>
                        </button>
                    </a>
                </div>
            </div>
        </div>

        <div class="footer-middle">
            <div class="container py-4">
                <div class="row">
                    <div class="col-lg-6 py-4">
                        <div class="d-flex align-items-center flex-wrap mb-3">
                            <img class="footer-logo" src="/media/img/logo_light_gray.svg" alt="Pikatool logo light gray"/>
                            <ul class="footer-menu ms-3">
                                <li>
                                    <a href="mailto:info@rlns.de" title="E-Mail">
                                        <MailIcon/>
                                        <span>info@rlns.de</span>
                                    </a>
                                </li>
                                <li>
                                    <router-link :to="{name: 'contact'}" title="Contact form">
                                        <SendIcon/>
                                        <span>Contact form</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>

                        <p>
                            Make your experience with our tools designed and developed with heart and love. While our main focus is on streamer tools, each project is a product of passion and commitment.
                        </p>
                    </div>
                    <div class="col-lg-3 py-4">
                        <h4>
                            <AboutIcon/>
                            <span>Information</span>
                        </h4>

                        <ul class="footer-menu mt-2">
                            <li><router-link :to="{name: 'privacy_policy'}">Privacy Policy</router-link></li>
                            <li><router-link :to="{name: 'about'}">About us</router-link></li>
                            <li><router-link :to="{name: 'contact'}">Contact</router-link></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 py-4">
                        <h4>
                            <BarsIcon/>
                            <span>Useful links</span>
                        </h4>

                        <ul class="footer-menu mt-2">
                            <li><router-link :to="{name: 'guides'}">Guides</router-link></li>
                            <li><a href="https://www.paypal.com/donate/?hosted_button_id=ZHXVKU5CF3JNS" target="_blank" :to="{name: 'donate'}">Donate</a></li>
                            <li><router-link :to="{name: 'contact'}">Contact</router-link></li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <div class="footer-border-top py-4">
                            <h4>
                                <DashboardIcon/>
                                <span>All tools</span>
                            </h4>

                            <div class="row">
                                <div v-for="(parent, index) of $root.nested_tools" :key="index" class="col-lg-4 py-3">
                                    <h5 class="fs-16 weight txt-primary weight-light">Tools for <span class="weight-normal">{{parent.title}}</span></h5>

                                    <ul class="footer-menu">
                                        <li v-for="(tool, index) of parent.children" :key="index">
                                            <ToolLink :tool="tool">
                                                <CaretIcon/>
                                                <span>{{tool.name}}</span>
                                                <Badge v-if="tool.badges.length > 0" v-for="(badge, index) of tool.badges" :class="badge.class">
                                                    {{badge.text}}
                                                </Badge>
                                            </ToolLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pb-4">
                            <h4 class="mb-3">
                                <HeartIcon/>
                                <span>Partners</span>
                            </h4>

                            <div class="d-flex flex-wrap">
                                <a href="https://www.mmoga.de/realtalk" target="_blank" class="partner">
                                    <img class="partner-logo" src="/media/img/partner/mmoga.webp" alt="MMOGA Logo">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container py-4">
                <div class="copyright">© 2022-{{year}} <router-link :to="{name: 'home'}">pikatool.com</router-link> | All rights reserved</div>
            </div>
        </div>

    </footer>
</template>

<script>
import HeartIcon from "@/components/icons/HeartIcon.vue";
import BarsIcon from "@/components/icons/BarsIcon.vue";
import DashboardIcon from "@/components/icons/DashboardIcon.vue";
import MailIcon from "@/components/icons/MailIcon.vue";
import SendIcon from "@/components/icons/SendIcon.vue";
import AboutIcon from "@/components/icons/AboutIcon.vue";
import Badge from "@/components/Badge.vue";
import CaretIcon from "@/components/icons/CaretIcon.vue";
import ToolLink from "@/components/ToolLink.vue";

export default {
    name: "Footer",
    components: {ToolLink, CaretIcon, Badge, AboutIcon, SendIcon, MailIcon, DashboardIcon, BarsIcon, HeartIcon},
    data: () => ({
        year: (new Date()).getFullYear()
    })
}
</script>

<style>
footer{
    position: relative;
}
footer .footer-top{
    background-color: #270405;
}
footer .footer-middle{
    background-color: var(--content-background-2);
}
footer .footer-bottom{
    background-color: var(--content-background-3);
}
footer .copyright{
    font-size: 0.8rem;
    color: var(--like-dark-white);
}
footer h4{
    color: var(--like-white);
    font-size: 0.9rem;
    font-weight: normal;
}
footer .footer-logo{
    height: 2rem;
}

footer .partner{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    background-color: var(--content-background-3);
    border-radius: 0.4rem;
}
footer .partner-logo{
    max-width: 100%;
    height: 2.8rem;
}

.footer-border-top{
    border-top: var(--min-border-width) solid var(--content-background-3);
}

.footer-menu{
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer-menu li a,
footer p{
    font-size: 0.8rem;
    color: var(--like-dark-white);
}
.footer-menu li a:hover{
    color: var(--like-white);
}

.heart-pulse{
    animation: heart-pulse-animation 1.2s linear infinite;
}
@keyframes heart-pulse-animation {
    10% {transform: scale(1.12); color: rgb(255, 25, 25);}
    20% {transform: scale(1); color: var(--red);}
    30% {transform: scale(1.12); color: rgb(255, 25, 25);}
}
</style>