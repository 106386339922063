<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor" class="icon">
        <path d="m18,22H4c-2.21,0-4-1.79-4-4V4C0,1.79,1.79,0,4,0h14c2.21,0,4,1.79,4,4v14c0,2.21-1.79,4-4,4Zm30-4V4c0-2.21-1.79-4-4-4h-14c-2.21,0-4,1.79-4,4v14c0,2.21,1.79,4,4,4h14c2.21,0,4-1.79,4-4Zm-26,26v-14c0-2.21-1.79-4-4-4H4c-2.21,0-4,1.79-4,4v14c0,2.21,1.79,4,4,4h14c2.21,0,4-1.79,4-4Zm26,0v-14c0-2.21-1.79-4-4-4h-14c-2.21,0-4,1.79-4,4v14c0,2.21,1.79,4,4,4h14c2.21,0,4-1.79,4-4Z"/>
    </svg>
</template>

<script>
export default {
    name: "DashboardIcon"
}
</script>