<template>
    <div class="sidebar-container">
        <nav class="sidebar">
            <div class="sidebar-content">
                <div v-for="(parent, index) of $root.nested_tools" :key="index" :class="[`sidebar-section sidebar-tool-${parent.id}`]">
                    <div class="sidebar-parent">
                        <div class="sidebar-main-width d-flex justify-content-center">
                            <div class="sidebar-padding pb-0">
                                <component v-if="parent.icon" :is="parent.icon" class="sidebar-parent-icon m-0"/>
                            </div>
                        </div>
                    </div>

                    <ul class="sidebar-tool-listing sidebar-padding pt-1">
                        <li v-for="(tool, index) of parent.children" :key="index">
                            <ToolLink :tool="tool" class="sidebar-tool-item">
                                <div class="sidebar-tool-item-icon-container">
                                    <img v-if="tool.icon" class="sidebar-tool-item-icon" :src="tool.icon['256']" :alt="`${tool.name} icon`"/>
                                    <img v-else class="sidebar-tool-item-icon" src="/media/img/icon/helmet-icon-256.png" alt="In construction icon">
                                </div>
                                <div class="sidebar-tool-name-container">
                                    <div class="sidebar-tool-name" :title="tool.name">{{tool.name_compact || tool.name}}</div>
                                </div>
                            </ToolLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import Badge from "@/components/Badge.vue";
import ToolLink from "@/components/ToolLink.vue";

export default {
    name: "Sidebar",
    components: {ToolLink, Badge},
    data: () => ({
        year: (new Date()).getFullYear()
    })
}
</script>

<style>
footer{
    padding-left: 4.8rem;
}
.main-content-wrapper{
    padding-left: 4.8rem;
}
.alert-container{
    left: 4.8rem!important;
}
@media screen and (min-width: 1100px) {
    .px-container{
        padding-left: 0!important;
    }
}
@media screen and (max-width: 1099px) {
    footer,
    .main-content-wrapper{
        padding-left: 0;
    }
    .alert-container{
        left: 0!important;
    }
    .sidebar-container{
        display: none;
    }
}

.sidebar-container{
    position: fixed;
    z-index: 8;
    top: 3.2rem;
    left: 0;
    padding: 1.6rem 1.6rem 0 0;
    height: calc(100vh - 3.2rem);
    background-color: var(--like-black);
}

.sidebar,
.sidebar .sidebar-main-width{
    width: 3.2rem;
}
.sidebar:hover,
.sidebar .sidebar-content,
.sidebar .sidebar-full-width{
    width: 16rem;
}
.sidebar{
    position: relative;
    background-color: var(--content-background-2);
    border-top-right-radius: 0.8rem;

    transition: 0.2s ease;
    overflow-x: hidden;
}
.sidebar-container .sidebar,
.sidebar-container .sidebar .sidebar-content{
    position: relative;
    padding-top: 0.2rem;
    min-height: 100%;
}

.sidebar .sidebar-parent{
    display: flex;
    align-items: center;
}
.sidebar .sidebar-parent .sidebar-parent-title{
    font-size: 0.6rem;
    color: var(--like-darkest-white);
}
.sidebar .sidebar-parent .sidebar-parent-icon{
    height: 0.8rem;
    max-width: 100%;
    color: var(--like-darkest-white);
}

.sidebar .sidebar-padding{
    padding: 0.6rem 0.4rem;
}
.sidebar .sidebar-section:not(:last-child){
    margin-bottom: 1rem;
}

.sidebar .sidebar-tool-listing{
    list-style: none;
    margin: 0;
}
.sidebar .sidebar-tool-item{
    display: flex;
    align-items: center;

    border-radius: 0.4rem;
}
.sidebar .sidebar-tool-listing li:not(:last-child){
    margin-bottom: 0.2rem;
}
.sidebar .sidebar-tool-item:hover{
    background-color: var(--like-black);
}
.sidebar .sidebar-tool-item .sidebar-tool-item-icon-container{
    position: relative;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0.2rem;
    border-radius: 0.4rem;

    margin-right: 0.6rem;
}
.sidebar .sidebar-tool-item:hover .sidebar-tool-item-icon-container,
.sidebar .sidebar-tool-item.router-link-active .sidebar-tool-item-icon-container{
    background-color: var(--primary);
}
.sidebar .sidebar-tool-item .sidebar-tool-item-icon{
    width: 100%;
    height: auto;
    filter: grayscale(1);
}
.sidebar .sidebar-tool-item:hover .sidebar-tool-item-icon,
.sidebar .sidebar-tool-item.router-link-active .sidebar-tool-item-icon{
    filter: none;
}
.sidebar .sidebar-tool-item .sidebar-tool-name-container{
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 2.4rem;
    padding-right: 0.4rem;

    overflow: hidden;
}
.sidebar .sidebar-tool-item:hover .sidebar-tool-name-container,
.sidebar .sidebar-tool-item.router-link-active .sidebar-tool-name-container{
    color: white;
}
.sidebar .sidebar-tool-item .sidebar-tool-name{
    width: 100%;
    font-size: 0.8rem;
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar .sidebar-tool-twitch .sidebar-tool-item:hover .sidebar-tool-item-icon-container,
.sidebar .sidebar-tool-twitch .sidebar-tool-item.router-link-active .sidebar-tool-item-icon-container{
    background-color: var(--tool-twitch);
}
.sidebar .sidebar-tool-kick .sidebar-tool-item:hover .sidebar-tool-item-icon-container,
.sidebar .sidebar-tool-kick .sidebar-tool-item.router-link-active .sidebar-tool-item-icon-container{
    background-color: var(--tool-kick);
}
.sidebar .sidebar-tool-lol .sidebar-tool-item:hover .sidebar-tool-item-icon-container,
.sidebar .sidebar-tool-lol .sidebar-tool-item.router-link-active .sidebar-tool-item-icon-container{
    background-color: var(--tool-lol);
}
</style>