<template>
    <div class="full-width-container">
        <div class="px-container py-container">
            <div class="content-box">
                <div class="content-box-section">
                    <div class="row">
                        <div class="col-lg-6 p-4 d-flex align-items-center justify-content-center justify-content-lg-end">
                            <h1 class="landing-title text-end">
                                <span class="text-pseudo-underline">Better</span><br>
                                <span class="text-pseudo-underline">Streaming</span><br>
                                for the<br>
                                best<br>
                                Streamers
                            </h1>
                        </div>
                        <div class="col-lg-6 p-4 d-flex align-items-center justify-content-center justify-content-lg-start">
                            <div class="limited-sm">
                                <h2 class="fs-24 text-start txt-primary text-uppercase mb-3">
                                    Improve<br>
                                    your<br>
                                    Streaming<br>
                                    Quality
                                </h2>
                                <p>
                                    Welcome to pikatool.com, the ultimate destination for enhancing your streaming journey! We're all about making your streaming experience top-notch. Whether you're new to streaming or a seasoned pro, our tools are designed to improve the quality of your streams. Easy-to-use and packed with features, we're here to help you shine online. Join a community of streamers with passion and let's make your streams the best they can be!
                                </p>
                            </div>
                        </div>
                        <div class="col-12 pt-2 pb-4">
                            <router-link :to="{name: 'tools'}" class="d-flex justify-content-center">
                                <button class="fs-20 btn btn-shadow btn-white">
                                    <DashboardIcon/>
                                    <span>Explore Tools</span>
                                </button>
                            </router-link>
                        </div>
                        <div class="col-12 py-5 position-relative">
                            <div class="vertical-dotted-line"></div>

                            <div class="d-flex justify-content-center position-relative">
                                <div class="d-flex justify-content-center align-items-center rounded-circle fs-24 bg-cbg-2" style="width:2.4rem;height:2.4rem;">
                                    <AngleIcon style="transform:rotate(90deg);"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pt-4">
                            <div>
                                <h3 class="fs-24 text-start txt-primary text-uppercase text-center mb-3">Featured Tools</h3>
                            </div>

                            <div>
                                <ToolListing :tools="$root.nested_tools.twitch.children" heading-level="4" :placeholders="2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-box mt-4">
                <div class="content-box-section">
                    <div class="row flex-lg-row-reverse">
                        <div class="col-lg-6 p-4 d-flex align-items-center justify-content-center justify-content-lg-start">
                            <h2 class="landing-title text-start">
                                <span class="text-pseudo-underline">User-</span><br>
                                <span class="text-pseudo-underline">Friendly</span><br>
                                for<br>
                                friendly<br>
                                users<br>
                            </h2>
                        </div>
                        <div class="col-lg-6 p-4 d-flex align-items-center justify-content-center justify-content-lg-end">
                            <div class="limited-sm">
                                <h3 class="fs-24 text-end txt-primary text-uppercase mb-3">
                                    Use<br>
                                    with<br>
                                    Ease<br>
                                    and<br>
                                    Smiles
                                </h3>
                                <p class="text-end">
                                    We believe in keeping things simple and straightforward. Our tools are designed and built with a focus on making them super easy for you to use — this is what we call 'usability.' From the look and feel to the way they work, everything is crafted to be user-friendly. And if you ever need a helping hand, our guides, complete with general videos for each tool, are there to break things down and make it all clear. Enjoy!
                                </p>
                            </div>
                        </div>
                        <div class="col-12 pt-2 pb-4">
                            <router-link :to="{name: 'guides'}" class="d-flex justify-content-center">
                                <button class="fs-20 btn btn-shadow btn-white">
                                    <GuideIcon/>
                                    <span>Explore Guides</span>
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-box bg-primary mt-4">
                <div class="content-box-section">
                    <div class="limited mx-auto">
                        <p class="txt-like-black">
                            <strong>Site in development.</strong><br>
                            Please note that the site is under development and may have some unfinished areas.<br>
                            <br>
                            Thank you :)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AngleIcon from "@/components/icons/AngleIcon.vue";
import ToolListing from "@/components/ToolListing.vue";
import DashboardIcon from "@/components/icons/DashboardIcon.vue";
import GuideIcon from "@/components/icons/GuideIcon.vue";

export default {
    name: "Home",
    components: {GuideIcon, DashboardIcon, ToolListing, AngleIcon}
}
</script>

<style>
.full-width-container{
    width: 100%;
}
</style>