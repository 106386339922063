<template>
    <slot v-if="authenticated" name="loggedIn"></slot>
    <slot v-else name="loggedOut"></slot>
</template>

<script>
export default {
    name: "Auth",
    emits: ['update'],
    data: () => ({
        auth: {
            twitch: {
                client_id: 't8ltl5mu0h9le3j3u2lypfj4k2co7m',
                redirect_uri: `https://auth.pikatool.com/callback/twitch`,
                scope: ['chat:read', 'user:read:email', 'channel:read:redemptions', 'moderator:read:followers', 'channel:read:subscriptions']
            }
        },
    }),
    computed: {
        authenticated: function(){
            return !!this.$root.user;
        },
        user_data: function(){
            const user = this.$root.user;
            if(user){
                const userData = user.connections[0].twitch_user;

                return {
                    displayName: userData.display_name,
                    avatar_url: userData.profile_image_url
                }
            }
            return null;
        }
    },
    watch: {
        authenticated: function(authenticated){
            this.$emit('update', authenticated);
        }
    },
    methods: {
        logIn(){
            let auth_window = window.open(`https://id.twitch.tv/oauth2/authorize?client_id=${this.auth.twitch.client_id}&redirect_uri=${encodeURIComponent(this.auth.twitch.redirect_uri)}&response_type=code&scope=${encodeURIComponent(this.auth.twitch.scope.join(' '))}`, '_blank', 'width=500,height=700');
        },
        logOut(){
            this.$root.removeAuthentication();
        }
    }
}
</script>

<style>

</style>