import { defineAsyncComponent } from 'vue'

import SparklesIcon from "@/components/icons/SparklesIcon.vue";
import ScreensIcon from "@/components/icons/ScreensIcon.vue";
import ModIcon from "@/components/icons/ModIcon.vue";
import BookmarkIcon from "@/components/icons/BookmarkIcon.vue";
import TwitchLogo from "@/components/icons/logo/TwitchLogo.vue";
import LeagueofLegendsLogo from "@/components/icons/logo/LeagueofLegendsLogo.vue";
import CoinsIcon from "@/components/icons/CoinsIcon.vue";
import EarthIcon from "@/components/icons/EarthIcon.vue";
import KickLogo from "@/components/icons/logo/KickLogo.vue";

export const parents = {

    'twitch': {
        id: 'twitch',
        title: "Twitch",
        icon: TwitchLogo,
        ref: "https://www.twitch.tv/",
    },
    /*
    'kick': {
        id: 'kick',
        title: "Kick",
        icon: KickLogo,
        ref: "https://kick.com/",
    },
    */
    'lol': {
        id: 'lol',
        title: "League of Legends",
        icon: LeagueofLegendsLogo,
        ref: "https://www.leagueoflegends.com/",
    }

};

export const tools = {

    'live-giveaway': {
        id: 'live-giveaway',
        name: "Live Giveaway",
        version: "1.3",
        widget: defineAsyncComponent(() => import('./views/widget/LiveGiveawayWidget.vue')),
    },

    'twitch-giveaway': {
        id: 'twitch-giveaway',
        name: "Twitch Giveaway",
        base: 'live-giveaway',
        tags: ['Streamer', 'Twitch', 'Giveaway'],
        icon: {
            '256': `/media/img/tool/twitch-giveaway/twitch-giveaway-256x.png`,
            '128': `/media/img/tool/twitch-giveaway/twitch-giveaway-128x.png`,
            '64': `/media/img/tool/twitch-giveaway/twitch-giveaway-64x.png`,
        },
        component: defineAsyncComponent(() => import('./components/tool/TwitchGiveaway.vue')),
        widget: defineAsyncComponent(() => import('./views/widget/TwitchGiveawayWidget.vue')),
        parent: 'twitch',
        badges: [{ text: "New Update" }],
        stats: [
            { key: 'views', title: `Views` },
            { key: 'spins', title: `Spins` },
            { key: 'channels', title: `Unique Channels` }
        ],
        about: {
            title: "A free and powerful Twitch Giveaway Tool",
            description: `Elevate your Twitch streaming experience with the Twitch Giveaway tool - your go-to solution for engaging and thrilling giveaways. It is not just a winner draw or a winner picker: Unleash the excitement with the Spin Animation, ensuring an entertaining winner reveal. Connect multiple chats and make collaborative giveaways with fellow streamers possible. Customize your giveaways with precision using the Role Configuration, allowing you to set rules and control participant roles. Plus, keep track of recent winners effortlessly. Twitch Giveaway combines innovation and functionality, making your giveaways seamless, interactive, and unforgettable. Join the fun and enhance your Twitch community engagement today!`,
            features: [
                {title: `Spin Animation`, description: `The spin animation is a guarantee for more excitement and fun during your giveaway.`, icon: SparklesIcon},
                {title: `Squad Mode`, description: `Connect multiple chats at the same time and organize a giveaway together with your streamer friends or partners.`, icon: ScreensIcon},
                {title: `Browser Source Widget`, description: `Integrate the Browser Source Widget for a nicer and smoother look in your stream and improved compatibility in Squad Mode.`, icon: EarthIcon},
                {title: `Channel Points Compatibility`, description: `Use your Twitch Channel Rewards and let your viewers enter the Giveaway by spending Channel Points.`, icon: CoinsIcon},
                {title: `Role Configuration`, description: `Organize the giveaway according to your rules and decide which role may join or change the luck of each role.`, icon: ModIcon},
                {title: `Recent Winnners`, description: `Keep track. Your winners and their messages will continue to be saved, even if the winner window is closed.`, icon: BookmarkIcon},
            ],
            background_video: `/media/video/twitch-giveaway-tool.webm`
        },
        meta: {
            description: `Add a touch of magic to your Twitch Giveaway here. A powerful Tool featuring Spin Animation, Channel Points Compatibility, Squad Mode and Role Configuration.`,
            keywords: `Twitch,Giveaway,Picker,Giveaway Picker,Giveaway App,Draw,Custom,Animation,Twitch Giveaway Tool,Twitch Streaming,Giveaway Enhancements,Spin Animation,Squad Mode,Browser Source,Widget,OBS,Role Configuration,Recent Winners,Twitch Community Engagement,Twitch Tool Features`,
        }
    },
    /*
    'twitch-ai-bot': {
        id: 'twitch-ai-bot',
        name: "Twitch AI Bot",
        version: "0.1",
        tags: ['Streamer', 'Twitch', 'Chat'],
        icon: {
            '256': `/media/img/tool/twitch-ai-bot/twitch-ai-bot-256x.png`,
            '128': `/media/img/tool/twitch-ai-bot/twitch-ai-bot-128x.png`,
            '64': `/media/img/tool/twitch-ai-bot/twitch-ai-bot-64x.png`,
        },
        component: defineAsyncComponent(() => import('./components/tool/TwitchAiBot.vue')),
        parent: 'twitch',
        badges: [{ text: 'Beta' }],
        about: {
            title: "An AI Twitch Chat Bot based on ChatGPT",
            description: `In testing beta phase...`,
            features: [
                {title: `Spin Animation`, description: `The spin animation is a guarantee for more excitement and fun during your giveaway.`, icon: SparklesIcon},
                {title: `Channel Points Compatibility`, description: `Use your Twitch Channel Rewards and let your viewers enter the Giveaway by spending Channel Points.`, icon: CoinsIcon},
                {title: `Squad Mode`, description: `Connect multiple chats at the same time and organize a giveaway together with your streamer friends or partners.`, icon: ScreensIcon},
                {title: `Role Configuration`, description: `Organize the giveaway according to your rules and decide which role may join or change the luck of each role.`, icon: ModIcon},
                {title: `Recent Winnners`, description: `Keep track. Your winners and their messages will continue to be saved, even if the winner window is closed.`, icon: BookmarkIcon},
            ]
        },
        meta: {
            description: `Add a touch of magic to your Twitch Giveaways here. A powerful Tool featuring Spin Animation, Channel Points Compatibility, Squad Mode and Role Configuration.`,
            keywords: `Twitch,Giveaway,Custom,Animation,Twitch Giveaway Tool,Twitch Streaming,Giveaway Enhancements,Spin Animation,Squad Mode,Role Configuration,Recent Winners,Twitch Community Engagement,Twitch Tool Features`,
        }
    },
    */
    /*
    'kick-giveaway': {
        id: 'kick-giveaway',
        name: "Kick Giveaway",
        tags: ['Streamer', 'Kick', 'Giveaway'],
        icon: {
            '256': `/media/img/tool/kick-giveaway/kick-giveaway-256x.png`,
            '128': `/media/img/tool/kick-giveaway/kick-giveaway-128x.png`,
            '64': `/media/img/tool/kick-giveaway/kick-giveaway-64x.png`,
        },
        component: defineAsyncComponent(() => import('./components/tool/KickGiveaway.vue')),
        widget: defineAsyncComponent(() => import('./views/widget/TwitchGiveawayWidget.vue')),
        parent: 'kick',
        badges: [{ text: "New" }],
        stats: [
            { key: 'views', title: `Views` },
            { key: 'spins', title: `Spins` },
            { key: 'channels', title: `Unique Channels` }
        ],
        about: {
            title: "A free and powerful Kick Giveaway Tool",
            description: `Elevate your Twitch streaming experience with the Twitch Giveaway tool - your go-to solution for engaging and thrilling giveaways. It is not just a winner draw or a winner picker: Unleash the excitement with the Spin Animation, ensuring an entertaining winner reveal. Connect multiple chats and make collaborative giveaways with fellow streamers possible. Customize your giveaways with precision using the Role Configuration, allowing you to set rules and control participant roles. Plus, keep track of recent winners effortlessly. Twitch Giveaway combines innovation and functionality, making your giveaways seamless, interactive, and unforgettable. Join the fun and enhance your Twitch community engagement today!`,
            features: [
                {title: `Spin Animation`, description: `The spin animation is a guarantee for more excitement and fun during your giveaway.`, icon: SparklesIcon},
                {title: `Squad Mode`, description: `Connect multiple chats at the same time and organize a giveaway together with your streamer friends or partners.`, icon: ScreensIcon},
                {title: `Browser Source Widget`, description: `Integrate the Browser Source Widget for a nicer and smoother look in your stream and improved compatibility in Squad Mode.`, icon: EarthIcon},
                {title: `Role Configuration`, description: `Organize the giveaway according to your rules and decide which role may join or change the luck of each role.`, icon: ModIcon},
                {title: `Recent Winnners`, description: `Keep track. Your winners and their messages will continue to be saved, even if the winner window is closed.`, icon: BookmarkIcon},
            ],
            background_video: `/media/video/twitch-giveaway-tool.webm`
        },
        meta: {
            description: `Add a touch of magic to your Twitch Giveaway here. A powerful Tool featuring Spin Animation, Channel Points Compatibility, Squad Mode and Role Configuration.`,
            keywords: `Twitch,Giveaway,Picker,Giveaway Picker,Giveaway App,Draw,Custom,Animation,Twitch Giveaway Tool,Twitch Streaming,Giveaway Enhancements,Spin Animation,Squad Mode,Browser Source,Widget,OBS,Role Configuration,Recent Winners,Twitch Community Engagement,Twitch Tool Features`,
        }
    },
    */
    'leagueoflegends-elo-output': {
        id: 'leagueoflegends-elo-output',
        name: "League of Legends Elo Output",
        name_compact: "LoL Elo Output",
        version: "1.0",
        parent: 'lol',
        external: "https://legacy.tooooooools.com/urlfetch/leagueoflegendselo",
        tags: ['League of Legends', 'Text Output', 'Twitch', 'YouTube', 'Nightbot', 'Streamelements'],
        badges: [{text: `Legacy`, class: `badge-light`}, {text: `Coming soon`, class: `badge-light`}],
    },
    'leagueoflegends-champion-mastery-output': {
        id: 'leagueoflegends-champion-mastery-output',
        name: "League of Legends Champion Mastery Output",
        name_compact: "LoL Champion Mastery Output",
        version: "1.0",
        parent: 'lol',
        external: "https://legacy.tooooooools.com/urlfetch/leagueoflegendsmastery",
        tags: ['League of Legends', 'Text Output', 'Twitch', 'YouTube', 'Nightbot', 'Streamelements'],
        badges: [{text: `Legacy`, class: `badge-light`}, {text: `Coming soon`, class: `badge-light`}],
    },

};
