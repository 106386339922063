import {io} from "socket.io-client";
import axios from "axios";

export const useEventSubSocket = async () => {
	const socket = io("wss://eventsub.pikatool.com", { path: '/' });
	await new Promise((resolve) => socket.on("connect", () => {
		console.info(`Connected to wss://eventsub.pikatool.com`);
		resolve();
	}));
	return socket;
}

export const asyncSocketEmit = (socket, eventName, data) => new Promise((resolve) => socket.emit(eventName, data, (response) => resolve(response)));

export const subscribeWidget = (socket, tool_id, token) => asyncSocketEmit(socket, 'subscribeWidget', { tool_id, token });

export const apiRequest = (url, config = {}) =>{
	return new Promise((resolve, reject) => {
		const handleResponse = (data) => resolve((typeof data === 'object')? data : false);

		if(config.access_token){
			if(!('headers' in config)) config.headers = {};
			config.headers['Authorization'] = `Bearer ${config.access_token}`;
		}

		axios.request({
			...config,
			url
		}).then(response => {
			handleResponse(response.data);
		}).catch(error => {
			if(error.response && error.response.data && typeof error.response.data === 'object' && 'success' in error.response.data){
				handleResponse(error.response.data);
			} else {
				reject(error);
			}
		});
	});
}
export const apiPostRequest = (url, data, config = {}) =>{
	return apiRequest(url, {...config, method: 'POST', data: data});
}